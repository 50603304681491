@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.footerContainer {
  position: relative;
  width: 100%;
  height: calc(60px + 14vw);
  background-color: #353a4b;
  color: white;
  font-size: 1vw;
  font-variant: small-caps;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5em;
  bottom: 0vw;
  z-index: 99;
}

.companyName {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: calc(12px + 1.5vw);
  font-weight: 800;
  margin-bottom: -0.1em;
  margin-top: 1.2vh;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
}

.copyright {
  position: relative;
  top: 0;
  font-weight: 300;
  font-size: calc(12px + 0.25vw);
  margin-bottom: 1.5vh;
}

.copyright span{
  font-size: 0.75em;
  font-weight: 800;
  margin-left: 0.25em;
}

.linksContainer a{
  color: white;
  font-size: 0.75em;
  font-weight: 300;
  text-decoration: none;
}

.linksContainer a:hover{
  text-decoration: underline;
}

.footerColumns {
  display: flex;
  justify-content: space-between;
}

.footerColumn {
  flex: 1;
}

.scrollTopButton{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2vw;
  height: 2vw;
  width: 2vw;
  padding: 0.5vw;
  border: 1px solid white;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.5s ease-in-out;

  &:hover{
    scale: (0.9);
  }
}

.disclosureText{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  font-size: 0.55vw;
  color: white;
}

.bcLogo{
  position: relative;
  height: calc(12px + 2vw);
  transition: all 0.3s ease;

  &:hover{
    scale: (.9);
  }
}

//Smartphone Adjustments
@media only screen and (max-width: 480px) {
  .footerContainer {
    height: 25vw;
    padding-bottom: 2vw;
  }

  .companyName {
    font-size: 3vw;
  }
  
  .copyright {
    font-size: 2.5vw;
  }

  .bcLogo{
    height: 6vw;
    bottom: 10%;
  
    &:hover{
      height: 5.75vw;
    }
  }

  .scrollTopButton{
    height: 5.5vw;
    width: 5.5vw;
  
    &:hover{
      height: 4.75vw;
      width: 4.75vw;
    }
  }

}